import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as $ from "jquery";
import { TableComponent } from "../../../library/common/components";
import axiosInstance from "../../../core/Axios";
import { URLS } from "../../../library/common/constants";
import {
  fetchManualOfferList,
  saveManualOfferFilter
} from "../ManualOfferAction";
import {
  ActionMessage,
  Form,
  Loader,
  strings
} from "../../../library/common/components";
import DeleteRow from "../../../library/common/components/Delete";
import ManualOfferListJson from "./ManualOfferListJson";
import { ManualOfferFilterConstant } from "./Frame/ManualOfferListFilterConstant";
import {
  handleAllFilter,
  isEmpty,
  removeErrorFieldsFromValues
} from "../../../library/utilities";

import UploadCsv from "../../../library/common/components/UploadCsv/UploadCsv";
import DownloadCsv from "../../../library/common/components/DownloadCsv/DownloadCsv";


export class ManualOfferList extends Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.__searchTimeout = null;
    this.state = {
      rowId: "",
      isLoading: false,
      isDeleted: null,
      initialRequest: {
        page: this.props.ManualOfferFilterCondition?.page,
        size: 10,
        filter: [],
        sort: [],
        graphql: null
      },
      multiFilterOperation: "AND",
      filters: {},
      messageBox: {
        display: false,
        type: "",
        message: ""
      }
    };
  }

  componentDidMount() {
    document.title = strings.ManualOffer_list_title;
    const {
      ManualOfferFilterCondition,
    } = this.props;
    const { initialRequest } = this.state;

    const isManualOfferObjectIsEmpty = isEmpty(ManualOfferFilterCondition?.filterValue);
    isManualOfferObjectIsEmpty > 0
      ? this.handleManualOfferFilter(ManualOfferFilterCondition?.filterValue)
      : this.setState(
        {
          initialRequest: {
            ...initialRequest,
            page: ManualOfferFilterCondition?.page
          }
        },
        () => {
        }
      );
  }


  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.initialRequest !== this.state.initialRequest) {
      const { fetchManualOfferList } = this.props;
      const { initialRequest } = this.state;
      this.setState({ isLoading: true });
      await fetchManualOfferList(initialRequest);
      this.setState({ isLoading: false });
    }
  }

  EditItem = async id => {
    this.props.history.push(`/edit-ManualOffer/${id}`);
  };

  userToggle = index => {
    this.toggleUser(index);
  };

  deleteItem = (index, isDeleted) => {
    $(`#delete-modal`).modal("show");
    this.setState({ rowId: index, isDeleted: isDeleted });
  };


  updateState = (id, value, deletedFlag) => {
    if (value === "edit") {
      this.EditItem(id);
    } else if (value === "toggleUser") {
      this.userToggle(id);
    } else {
      this.deleteItem(id, deletedFlag);
    }
  };

  toggleUser = value => {
    this.setState({ isLoading: true });
    axiosInstance
      .put(URLS.toggleUsers(value))
      .then(({ status }) => {
        if (status === 200) {
          const { fetchManualOfferList } = this.props;
          const { initialRequest } = this.state;
          fetchManualOfferList(initialRequest);
          this.setState({ isLoading: false });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
        if (err.hasOwnProperty("response")) {
          this.setActionMessage(true, "Error", err.response.data.message);
        } else {
          this.setActionMessage(true, "Error", "Oops! Something went wrong.");
        }
      });
  };

  setActionMessage = (display = false, type = "", message = "") => {
    let setActionMessage = {
      display: display,
      type: type,
      message: message
    };
    this.setState({ messageBox: setActionMessage });
  };
  handleFilterAndSorting = filteredValue => {
    this.setState({ initialRequest: filteredValue });
    const { ManualOfferFilterCondition, saveManualOfferFilter } = this.props;
    saveManualOfferFilter({ ...ManualOfferFilterCondition, page: filteredValue.page });
  };

  handleSearch = (value, name, finalInputData) => {
    const { filters } = this.state;
    const newFilters = { ...filters };
    newFilters[name] = {
      value,
      operation: "cic"
    };
    this.setState({ filters: newFilters }, () => {
      this.handleManualOfferFilter(finalInputData);
    });
  };

  handleManualOfferFilter = async finalInputData => {
    const { ManualOfferFilterCondition, saveManualOfferFilter } = this.props;
    saveManualOfferFilter({ ...ManualOfferFilterCondition, filterValue: finalInputData });
    this.setState(
      {
        filters: {
          brand: { value: finalInputData?.brand || "" },
          brandIdentifier: { value: finalInputData?.brandIdentifier || "" },
          clientPosCode: { value: finalInputData?.clientPosCode || "" },
          ean: { value: finalInputData?.ean || "" },
          loc: { value: finalInputData?.loc || "" },
          period: { value: finalInputData?.period || "" },
          segment: { value: finalInputData?.segment || "" },
          sku: { value: finalInputData?.sku || "" },
          skuIdentifier: { value: finalInputData?.skuIdentifier || "" }
        }
      },
      () => {
        this.handleManualOfferUpdatedFilter();
      }
    );
  };

  handleManualOfferUpdatedFilter = async () => {
    const { filters, initialRequest, multiFilterOperation } = this.state;
    if (filters) {
      const filteredValue = await handleAllFilter(
        filters,
        multiFilterOperation,
        "cic"
      );
      if (this.__searchTimeout) {
        clearTimeout(this.__searchTimeout);
      }
      this.__searchTimeout = setTimeout(() => {
        this.setState({
          initialRequest: { ...initialRequest, filter: filteredValue, page: 1 }
        });
      }, 500);
    }
  };

  handleFormChange = (field, value, inputData) => {
    let fieldValue = value;
    const fields = { field: fieldValue };
    let initialData = { ...inputData, ...fields };
    const finalInputData = removeErrorFieldsFromValues(initialData);
    this.handleSearch(fieldValue, field, finalInputData);
  };

  handleClearFilter = () => {
    const { ManualOfferFilterCondition, saveManualOfferFilter } = this.props;
    this.setState(
      {
        initialRequest: {
          page: 1,
          size: 10,
          filter: [],
          sort: [],
          graphql: null
        },
        filters: {},
      },
      () => {
        saveManualOfferFilter({
          ...ManualOfferFilterCondition,
          filterValue: this.state.filters,
          page: 1
        });
      }
    );
    this.formRef.resetForm();
  };

  updatedFilter = () => {
    const { initialRequest } = this.state;
    const newPage = initialRequest.page > 1 ? initialRequest.page - 1 : 1;
    this.setState({ initialRequest: { ...initialRequest, page: newPage } });
  };

  filterArray = () => {
    const { filters, multiFilterOperation } = this.state;
    let filteredValue = [];
    if (Object.keys(filters).length > 0) {
      filteredValue = handleAllFilter(
        filters,
        multiFilterOperation,
        "cic"
      );
    }
    return filteredValue
  };

  render() {
    const {
      rowId,
      isLoading,
      messageBox,
      initialRequest,
      isDeleted
    } = this.state;
    const {
      permission,
      ManualOfferList,
      fetchManualOfferList,
      ManualOfferFilterCondition,
    } = this.props;
    return (
      <div className="main-content table-container">
        <div>
          {messageBox.display && (
            <ActionMessage
              message={messageBox.message}
              type={messageBox.type}
              styleClass={messageBox.styleClass}
            />
          )}
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 table-wrapper">
            <div className="d-flex justify-content-between align-items-center topFilter">
              <div className="d-flex">
                <h3 style={{ color: "#F36633", fontWeight: "700" }}>{strings.ManualOffer_list_title}</h3>
                <button
                  title={strings.search_filter_text}
                  className="btn advanceSearch mt-0"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="fa fa-filter" aria-hidden="true" />
                </button>
                <button
                  title={strings.clear_filter_text}
                  className="btn advanceSearch ml-2"
                  onClick={this.handleClearFilter}
                >
                  <i className="fa fa-refresh" aria-hidden="true" />
                </button>
                <UploadCsv
                  apiUrl={URLS.uploadManualOfferBulk}
                  title="Upload Excel"
                />
                <DownloadCsv
                  apiUrl={URLS.manualOffersListDownload}
                  title="Download Manual Offers Excel"
                  filters={() => this.filterArray()}
                />
              </div>
            </div>
            <div
              className="collapse mb-1 mt-4 searchFilter"
              id="collapseExample"
            >
              <div className="card card-body">
                <span className="search_filter_text">
                  {strings.search_filter_text}
                </span>
                <div className="searchWrapper">
                  <Form
                    values={ManualOfferFilterCondition?.filterValue}
                    onChange={this.handleFormChange}
                    model={ManualOfferFilterConstant(strings, true)}
                    ref={el => (this.formRef = el)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 table-wrapper">
                {isLoading && <Loader />}
                <TableComponent
                  formList={ManualOfferListJson(permission)}
                  initialSort="username"
                  filteredData={ManualOfferList}
                  permission={permission}
                  eventFunction={this.updateState}
                  users={ManualOfferList}
                  initialRequest={initialRequest}
                  handleFilterAndSorting={this.handleFilterAndSorting}
                />
              </div>
            </div>
            <DeleteRow
              updatedFilter={this.updatedFilter}
              totalSize={ManualOfferList?.numberOfElements}
              bodyText={isDeleted === false && strings.ManualOfferDeleteMessage}
              initialRequest={initialRequest}
              deleteUrl={URLS.deleteManualOffer(rowId)}
              updatedList={fetchManualOfferList}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer, ManualOffer }) => {
  return {
    permission: authReducer.token.privileges,
    ManualOfferList: ManualOffer.ManualOfferList,
    ManualOfferFilterCondition: ManualOffer.ManualOfferFilterCondition
  };
};
export default connect(mapStateToProps, {
  fetchManualOfferList,
  saveManualOfferFilter,
})(ManualOfferList);
