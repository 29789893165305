import React, { useEffect, useState } from "react";
import { FileUploader, strings } from "../../components";
import ImageDisplay from "../ImageDisplay/ImageDisplay";
import swal from "sweetalert";
import { fetchFromStorage } from "../../../utilities";

const DataUpload = ({
  setIsUpload,
  media,
  label,
  handleValue,
  deletedImage,
  category,
  maxSize,
  accept,
  uploadSubText,
  clearImages,
  uploadAllowed
}) => {
  const [demandLetterFile, setDemandLetterFile] = useState([]);
  const [demandLetterDeletedFile, setDemandLetterDeletedFile] = useState("");
  useEffect(() => {
    const newFiles = [];
    if (media && media.length > 0) {
      for (let i = 0; i < media.length; i++) {
        const objFile = {};
        if (
          media[i].id !== newFiles[i]?.id &&
          media[i]?.category === category
        ) {
          objFile.preview = media[i].url;
          objFile.id = media[i].id;
          objFile.type = media[i].contentType;
          objFile.category = media[i].category;
          objFile.name = media[i].fileName;
          newFiles.push(objFile);
        }
      }
      setDemandLetterFile(newFiles);
    }
  }, [media, category]);

  useEffect(() => {
    handleValue(demandLetterFile, category);
  }, [demandLetterFile, category, handleValue]);

  useEffect(() => {
    deletedImage(demandLetterDeletedFile, category);
  }, [demandLetterDeletedFile, category, deletedImage]);
  useEffect(() => {
    if (clearImages) {
      setDemandLetterFile([]);
    }
  }, [clearImages]);

  const handleFileUpload = files => {
    if (setIsUpload) {
      setIsUpload(true);
    }
    const filesArray = files.map(file =>
      Object.assign(file, {
        mediaType: "other"
      })
    );
    setDemandLetterFile(files => [...files, ...filesArray]);
  };

  const handleRemoveImage = index => () => {
    const files = demandLetterFile.filter((item, j) => index !== j);
    setDemandLetterFile(files);
    if (media) {
      const deletedFile = demandLetterFile.filter((item, j) => index === j);
      let deleted = [...demandLetterDeletedFile];
      if (
        deletedFile.length > 0 &&
        deletedFile[0].id !== 0 &&
        deletedFile[0].id !== undefined
      ) {
        deleted.push(deletedFile[0].id);
        setDemandLetterDeletedFile(deleted);
        if (setIsUpload) {
          setIsUpload(true);
        }
      }
    }
  };

  const handleRejected = () => {
    swal("", strings.upload_demand_errorMessage, "error");
  };

  const isImagesFromS3 = fetchFromStorage("token");
  return (
    <div className="customer-container additionalTab mb-3">
      <div className="row">
        <div className="col-sm-12">
          {uploadAllowed && (
            <FileUploader
              handleRejected={handleRejected}
              maxSize={
                isImagesFromS3?.isS3StorageEnabled ? 100000000000 : maxSize
              }
              multiple
              accept={accept}
              label={label}
              onDrop={handleFileUpload}
              uploadSubText={
                isImagesFromS3?.isS3StorageEnabled ? "" : uploadSubText
              }
            />
          )}
          <ImageDisplay
            uploadAllowed={uploadAllowed}
            files={demandLetterFile}
            onRemove={e => handleRemoveImage(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default DataUpload;
