import { storeConstants } from "../../library/common/constants";

const initialState = {
  LocalizationListData: [],
  LocalizationFilterCondition: {
    page: 1,
    filter: [],
    sort: [],
    graphql: null,
    size: 10,
    filterValue: {}
  }
};

const LocalizationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case storeConstants.FETCH_LOCALIZATION:
      return {
        ...state,
        LocalizationListData: action.payload
      };
    case storeConstants.PAGE_NUMBER_USER:
      return {
        ...state,
        userFilterCondition: action.payload
      };
    default:
      return state;
  }
};

export default LocalizationReducer;
