/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { strings } from "../../library/common/components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Font,
  Alignment,
  Underline,
  Link
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import { useEffect } from "react";
import "./index.css";
import swal from "sweetalert";
import axiosInstance from "../../core/Axios";
import { URLS } from "../../library/common/constants";

const toolsItems = [
  "fontSize",
  "fontColor",
  "|",
  "bold",
  "italic",
  "underline",
  "link",
  " | ",
  "alignment:left",
  "alignment:center",
  "alignment:right",
  " | ",
  "undo",
  "redo",
];
const pulginsItems = [
  Bold,
  Essentials,
  Italic,
  Mention,
  Underline,
  Paragraph,
  Undo,
  Font,
  Alignment,
  Link
];

const TermsEditor = () => {
  const [editorData, setEditorData] = useState("");
  const [selectedLang, setSelectedLang] = useState("");
  const [locList, setLocList] = useState([]);
  const [selectedLoc, setSelectedLoc] = useState("");
  const [languagesData, setLanguagesData] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [tncData, setTncData] = useState([]);
  const [pageType, setPageType] = useState("homepage");

  const saveTermCondition = async () => {
    try {
      if (selectedLang === "") {
        swal("", "Please select language", "error");
        return;
      }
      if (editorData === "") {
        swal("", "Please add data", "error");
        return;
      }
      await axiosInstance.post(URLS.saveTNC, {
        loc: selectedLoc,
        type: pageType,
        termsAndConditon: editorData,
        language: selectedLang,
      });
      await getTNCData();
      swal("", "Saved successfully!", "success");
    } catch (e) {
      swal("", "Something went wrong", "error");
    }
  };

  const getLanguages = async () => {
    try {
      const { data } = await axiosInstance.get(URLS.getLangauges);
      if (data && data.length > 0) {
        const uniqueLoc = new Set(data.map((item) => item.loc));
        setLocList([...uniqueLoc]);
        setLanguagesData(data);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const getTNCData = async () => {
    try {
      const { data } = await axiosInstance.get(URLS.getTNCData);
      if (data && data.length > 0) {
        setTncData(data);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    getLanguages();
    getTNCData();
  }, []);

  useEffect(() => {
    setEditorData("");
    setSelectedLang("");
    if (selectedLoc !== "") {
      const availableLanguage = languagesData.filter(
        (langItem) => langItem.loc === selectedLoc
      );
      setLanguagesList(availableLanguage);
    } else {
      setLanguagesList([]);
    }
  }, [selectedLoc]);

  useEffect(() => {
    if (selectedLang !== "") {
      const findExistingData = tncData.find(
        (tnc) =>
          tnc.loc === selectedLoc &&
          tnc.type === pageType &&
          tnc.languageCode === selectedLang
      );
      if (findExistingData) {
        setEditorData(findExistingData.termsAndCondition);
      } else {
        setEditorData("");
      }
    }
  }, [selectedLang, pageType]);

  return (
    <div className="terms-main-container">
      <div className="terms-heading-container">
        <h3 className="terms-editor-title">{strings.termEditorTitle}</h3>
        <select
          value={selectedLoc}
          style={{ height: "30px", padding: "0px 6px " }}
          onChange={(e) => {
            setSelectedLoc(e.target.value);
          }}
        >
          <option value={""}>Select Loc</option>
          {locList.map((loc) => {
            return (
              <option key={loc} value={loc}>
                {loc}
              </option>
            );
          })}
        </select>
        <select
          value={pageType}
          style={{ height: "30px", padding: "0px 6px " }}
          onChange={(e) => {
            setPageType(e.target.value);
          }}
        >
          <option key={"1"} value={"homepage"}>
            Home Page
          </option>
          <option key={"2"} value={"termAndCondition"}>
            Term and Condition
          </option>
          <option key={"3"} value={"footer"}>
            Footer
          </option>
        </select>
        <select
          value={selectedLang}
          style={{ height: "30px", padding: "0px 6px " }}
          onChange={(e) => {
            setSelectedLang(e.target.value);
          }}
        >
          <option key={"1"} value={""}>
            Select Language
          </option>
          {languagesList.map((lang, index) => {
            return (
              <option key={lang.languageCode + index} value={lang.languageCode}>
                {lang.languageCode}
              </option>
            );
          })}
        </select>
      </div>
      <CKEditor
        data={editorData}
        onChange={(event, editor) => {
          const data = editor.getData();
          setEditorData(data);
        }}
        editor={ClassicEditor}
        config={{
          placeholder: "Write your content here",
          toolbar: {
            items: toolsItems,
          },
          plugins: pulginsItems,
          mention: {},
          initialData: "",
        }}
        className="custom-editor"
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button className="term-save-button" onClick={saveTermCondition}>
          Save
        </button>
      </div>
    </div>
  );
};

export default TermsEditor;
