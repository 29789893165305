import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import './fileUploaderStyles.scss';
import { strings } from '../index';

const FileUploader = ({
  accept,
  label,
  multiple,
  onDrop,
  helpText,
  isHelp,
  icon,
  uploadText,
  uploadSubText,
  maxSize,
  handleRejected,
}) => {
  const handleFileDrop = files => {
    onDrop(
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  };

  return (
    <Dropzone
      onDropRejected={handleRejected}
      maxSize={maxSize}
      accept={accept}
      multiple={multiple}
      onDrop={handleFileDrop}>
      {({ getRootProps, getInputProps }) => (
        <div className="file-uploader">
          {label && (
            <p className="mb-1 inputField-label">
              {label}{' '}
              {isHelp && (
                <span
                  style={{ display: 'none' }}
                  className="help"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={`${strings.helpText}`}>
                  <i className="ml-2 fa fa-info-circle" />
                </span>
              )}
            </p>
          )}
          <div
            {...getRootProps({
              className: 'dropzone d-flex justify-content-center align-items-center',
            })}>
            <input {...getInputProps()} />
            {icon && <i className="attachment fa fa-paperclip" />}
            {!icon && (
              <p>
                {uploadText}
                <br />
                {uploadSubText}
              </p>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

FileUploader.propTypes = {
  accept: PropTypes.string,
  uploadText: PropTypes.string,
  label: PropTypes.string,
  maxSize: PropTypes.number,
  uploadSubText: PropTypes.string,
  helpText: PropTypes.string,
  multiple: PropTypes.bool,
  isHelp: PropTypes.bool,
  icon: PropTypes.bool,
  handleRejected: PropTypes.func,
  onDrop: PropTypes.func.isRequired,
};

FileUploader.defaultProps = {
  accept: '',
  handleRejected: () => {},
  label: '',
  multiple: false,
  helpText: '',
  isHelp: true,
  icon: false,
  maxSize: 5000000,
  uploadText: 'Drag drop some files here, or click to select files',
  uploadSubText: 'File size: 5mb, Supported Type: pdf, .png, .jpg, .doc',
};

export default FileUploader;
