import React from 'react';
import Button from '../Button';
import './imageDisplayStyles.scss';

const ImageDisplay = ({ files, onRemove, uploadAllowed }) => {
  const imageFile = file => {
    return (
      <a title={file.name} download rel="noopener noreferrer" target="_blank" href={file.preview}>
        <img src={file.preview} alt={file.name} />
      </a>
    );
  };
  const typeValue = file => {
    switch (file.type) {
      case 'application/pdf':
        return (
          <div className="pdf" title={file.name}>
            <div className="pdf-file">
              <a download target="_blank" rel="noopener noreferrer" href={file.preview}>
                {file.name} <i className="fa fa-download ml-2" aria-hidden="true" />
              </a>
            </div>
            <div className="icon">
              <i className="fa fa-file-pdf-o" aria-hidden="true" />
            </div>
          </div>
        );
      case 'video/mp4':
        return (
          <div className="pdf" title={file.name}>
            <div className="pdf-file">
              <a download target="_blank" rel="noopener noreferrer" href={file.preview}>
                {file.name} <i className="fa fa-file-video-o ml-2" aria-hidden="true" />
              </a>
            </div>
            <div className="icon">
              <i className="fa fa-file-video-o" aria-hidden="true" />
            </div>
          </div>
        );
      case 'image/png':
        return file.preview !== null && imageFile(file);
      case 'image/jpg':
        return file.preview !== null && imageFile(file);
      case 'image/jpeg':
        return file.preview !== null && imageFile(file);
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return (
          <div className="pdf" title={file.name}>
            <div className="pdf-file">
              <a download target="_blank" rel="noopener noreferrer" href={file.preview}>
                {file.name}
              </a>
            </div>
            <div className="icon">
              <i className="fa fa-file-text-o" aria-hidden="true" />
            </div>
          </div>
        );
      case 'application/zip':
        return (
          <div className="pdf" title={file.name}>
            <div className="pdf-file">
              <a download target="_blank" rel="noopener noreferrer" href={file.preview}>
                {file.name}
              </a>
            </div>
            <div className="icon">
              <i className="fa fa-file-zip-o" aria-hidden="true" />
            </div>
          </div>
        );
      case 'application/octet-stream':
        return (
          <div className="pdf" title={file.name}>
            <div className="pdf-file">
              <a download target="_blank" rel="noopener noreferrer" href={file.preview}>
                {file.name}
              </a>
            </div>
            <div className="icon">
              <i className="fa fa-file-text-o" aria-hidden="true" />
            </div>
          </div>
        );
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/msword':
      case 'application/x-tika-ooxml':
      case 'text/csv':
      case 'text/plain':
        return (
          <div className="pdf" title={file.name}>
            <div className="pdf-file">
              <a download target="_blank" rel="noopener noreferrer" href={file.preview}>
                {file.name}
              </a>
            </div>
            <div className="icon">
              <i className="fa fa-file-text-o" aria-hidden="true" />
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div className="image-display-list">
      {files.map((file, key) => (
        <div className="image-item" key={key}>
          {uploadAllowed && <Button onClick={onRemove(key)} icon="fa-trash" styleClass="remove-button" />}
          {typeValue(file)}
        </div>
      ))}
    </div>
  );
};

export default ImageDisplay;
