import axiosInstance from "../../../core/Axios";
import {
  identifiers,
  storeConstants,
  URLS
} from "../../../library/common/constants";
import {
  fetchFromStorage,
  removeFromStorage,
  saveToStorage
} from "../../../library/utilities";
import { devEnv } from "./authConstants"

export const setAuthentication = token => dispatch => {
  saveToStorage(identifiers.token, token);
  dispatch({
    type: storeConstants.SET_AUTHENTICATION,
    payload: token
  });
};

export const toggleSidebar = flag => dispatch => {
  dispatch({
    type: storeConstants.SIDEBAR_TOGGLE,
    payload: flag
  });
};


export const getAuth = () => dispatch => {
  const token = fetchFromStorage(identifiers.token);
  if (token) {
    dispatch(setAuthentication(token));
  }
};

export const logoutUser = () => dispatch => {
  axiosInstance.post(URLS.logout).then(({ status }) => {
    if (status === 200) {
      dispatch({ type: storeConstants.LOGOUT });
    }
    removeFromStorage(identifiers.token);
  });
};

export const refreshToken = () => dispatch => {
  const body = new URLSearchParams();
  const token = fetchFromStorage(identifiers.token);

  body.append("refresh_token", token.refresh_token);
  body.append("scope", "webclient");
  body.append("grant_type", "refresh_token");
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    auth: {
      username: devEnv.devUserName,
      password: devEnv.devPass
    }
  };

  return axiosInstance
    .post(URLS.login, body, config)
    .then(({ data, status }) => {
      if (status === 200) {
        removeFromStorage(identifiers.token);
        dispatch(setAuthentication(data));
      }
    });
};

export const getTheme = () => dispatch => {
  return axiosInstance
    .get(URLS.Theme)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: storeConstants.THEME,
          payload: data
        });
        saveToStorage(identifiers.theme, data);
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.THEME,
        payload: []
      });
    });
};



export const fetchRoleList = postData => dispatch => {
  return axiosInstance
    .post(URLS.getRolePage, postData)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: storeConstants.ROLE_LIST,
          payload: data
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.ROLE_LIST,
        payload: []
      });
    });
};

export const saveRoleListFilter = pageNumber => dispatch => {
  dispatch({
    type: storeConstants.PAGE_NUMBER_ROLE_LIST,
    payload: pageNumber
  });
};


export const saveUserName = name => dispatch => {
  dispatch({
    type: storeConstants.USERNAME,
    payload: name
  });
};

export const getNotificationCount = data => dispatch => {
  const value = parseInt(data);
  dispatch({
    type: storeConstants.NOTIFICATION_COUNT,
    payload: value
  });
};
