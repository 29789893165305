import React, { useState } from "react";
import PropTypes from "prop-types";

import "./checkboxStyle.scss";
import { validateInput } from "../../../utilities/Validators";

const Checkbox = ({
  testId,
  label,
  selected,
  disabled,
  onChange,
  validators,
  field
}) => {
  const [error, setError] = useState(null);
  const handleChange = event => {
    const inputError = validateInput(validators, event.target.checked);
    setError(inputError);
    onChange(event.target.checked, field, inputError);
  };

  return (
    <div>
      <label className="checkbox-container" data-test={testId}>
        {label && <p className="label">{label}</p>}
        <input
          type="checkbox"
          checked={selected}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className="checkmark"> </span>
      </label>
      {error && error.error && (
        <span className="text-danger">{error.message}</span>
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  testId: "",
  disabled: false,
  validators: [],
  field: "",
  label: ""
};

Checkbox.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  validators: PropTypes.array,
  field: PropTypes.string
};

export default Checkbox;
