import * as $ from "jquery";
import React, { lazy } from "react";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { getTheme } from "../../library/common/actions";
import { MainWrapper } from "../../library/utilities/styledcss";
import { theme } from "../../library/utilities/Theme";
import PrivateRoute from "./PrivateRoute";
import ManualOfferList from "../../modules/ManualOffer/ManualOfferList";
import EventConfigurationList from "../../modules/EventConfiguration/EventConfigurationList";
import EventHistoryList from "../../modules/EventHistory/EventHistoryList";
import Checkout from "../../modules/Checkout";
import LocCheckout from "../../modules/PortugalCheckout";
import { portugalLabels, spainLabels } from "../../modules/PortugalCheckout/labels";
import TermsEditor from "../../modules/TermsEditor/TermsEditor";
const EditProfile = lazy(() => import("../../modules/UserProfile/EditProfile"));
const UserList = lazy(() => import("../../modules/UserManagement/UserList"));
const CreateUser = lazy(() =>
  import("../../modules/UserManagement/AddEditUser")
);
const CreatePharmacyUsers = lazy(() =>
  import("../../modules/PharmacyUsers/AddEditPharmacyUsers")
);
const CreateOfferPeriod = lazy(() =>
  import("../../modules/OfferPeriod/AddEditOfferPeriod")
);

const CreateOrder = lazy(() => import("../../modules/Order/AddEditOrder"));

const CreateClientPosAccount = lazy(() =>
  import("../../modules/ClientPosAccount/AddEditClientPosAccount")
);
const CreateSegmentation = lazy(() =>
  import("../../modules/Segmentation/AddEditSegmentation")
);
const CreatePredefinedOffer = lazy(() =>
  import("../../modules/PredefinedOffer/AddEditPredefinedOffer")
);
const CreateStandardSegmentOffer = lazy(() =>
  import("../../modules/StandardSegmentOffer/AddEditStandardSegmentOffer")
);
const CreateValidOffer = lazy(() =>
  import("../../modules/ValidOffer/AddEditValidOffer")
);
const CreateCommercialCondition = lazy(() =>
  import("../../modules/CommercialCondition/AddEditCommercialCondition")
);
const CreateWholesalers = lazy(() =>
  import("../../modules/Wholesalers/AddEditWholesalers")
);
const CreateAccounts = lazy(() =>
  import("../../modules/Accounts/AddEditAccounts")
);
const Privilege = lazy(() => import("../../modules/Role/privilege"));
const AddRole = lazy(() => import("../../modules/Role/CreateAddRole"));
const RoleList = lazy(() => import("../../modules/Role"));
const PharmacyUsersList = lazy(() =>
  import("../../modules/PharmacyUsers/PharmacyUsersList")
);
const OfferPeriodList = lazy(() =>
  import("../../modules/OfferPeriod/OfferPeriodList")
);

const OrderList = lazy(() => import("../../modules/Order/OrderList"));

const ClientPosAccountList = lazy(() =>
  import("../../modules/ClientPosAccount/ClientPosAccountList")
);
const SegmentationList = lazy(() =>
  import("../../modules/Segmentation/SegmentationList")
);
const PredefinedOfferList = lazy(() =>
  import("../../modules/PredefinedOffer/PredefinedOfferList")
);
const StandardSegmentOfferList = lazy(() =>
  import("../../modules/StandardSegmentOffer/StandardSegmentOfferList")
);
const ValidOfferList = lazy(() =>
  import("../../modules/ValidOffer/ValidOfferList")
);
const CommercialConditionList = lazy(() =>
  import("../../modules/CommercialCondition/CommercialConditionList")
);
const WholesalersList = lazy(() =>
  import("../../modules/Wholesalers/WholesalersList")
);

const FeedbackList = lazy(() =>
  import("../../modules/Feedback/FeedbackList")
);
const ExtarnalWholesalerMappingist = lazy(() =>
  import("../../modules/ExtarnalWholesalerMapping/ExtarnalWholesalerMappingList")
);
const LocOrderList = lazy(() =>
  import("../../modules/LocOrder/LocOrderList")
);

const LinkTableList = lazy(() =>
  import("../../modules/LinkTable/LinkTableList")
);

const DraftTableList = lazy(() =>
  import("../../modules/DraftTable/DraftTableList")
);

const LocalizationList = lazy(() =>
  import("../../modules/Localization/LocalizationList")
);

const LanguagesTableList = lazy(() =>
  import("../../modules/LanguagesTable/LanguagesTableList")
);

const AccountsList = lazy(() => import("../../modules/Accounts/AccountsList"));
const Login = lazy(() => import("../../modules/Login"));
const ForgotPassword = lazy(() => import("../../modules/Login/ForgotPassword"));
const UserProfile = lazy(() => import("../../modules/UserProfile"));
const Routes = (props) => {
  window.onhashchange = function () {
    const value = $("div").is(".modal-backdrop.show");
    if (value) {
      $(".modal-backdrop").remove();
    }
  };
  const { isLoggedIn } = props;
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/userManagement" } };
  console.log('from', location.state)
  return (
    <div>
      <ThemeProvider theme={theme}>
        <MainWrapper>
          <Switch>
            <Route
              exact
              path="/"
              render={() => {
                if (isLoggedIn && from) {
                  return <Redirect to={from} />;
                } else {
                  return <Redirect to="/login" />;
                }
              }}
            />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/userManagement"
              component={UserList}
            />

            {/* chat bot user route */}

            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/PharmacyUsers"
              component={PharmacyUsersList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/External-wholesaler-mapping"
              component={ExtarnalWholesalerMappingist}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/OfferPeriod"
              component={OfferPeriodList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/Order"
              component={OrderList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/ClientPosAccount"
              component={ClientPosAccountList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/Segmentation"
              component={SegmentationList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/PredefinedOffer"
              component={PredefinedOfferList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/StandardSegmentOffer"
              component={StandardSegmentOfferList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/ValidOffer"
              component={ValidOfferList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/CommercialCondition"
              component={CommercialConditionList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/Wholesalers"
              component={WholesalersList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/Accounts"
              component={AccountsList}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-PharmacyUsers"
              component={CreatePharmacyUsers}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-OfferPeriod"
              component={CreateOfferPeriod}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-Order"
              component={CreateOrder}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-ClientPosAccount"
              component={CreateClientPosAccount}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-Segmentation"
              component={CreateSegmentation}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-PredefinedOffer"
              component={CreatePredefinedOffer}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-StandardSegmentOffer"
              component={CreateStandardSegmentOffer}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-ValidOffer"
              component={CreateValidOffer}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-CommercialCondition"
              component={CreateCommercialCondition}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/add-userManagement"
              component={CreateUser}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-Wholesalers"
              component={CreateWholesalers}
            />
            <PrivateRoute
              isLoggedIn={isLoggedIn}
              path="/add-Accounts"
              component={CreateAccounts}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-userManagement/:id"
              component={CreateUser}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/userProfile"
              component={UserProfile}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/editProfile/:id"
              component={EditProfile}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/role-list"
              component={RoleList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/add-role"
              component={AddRole}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-role/:id"
              component={AddRole}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-PharmacyUsers/:id"
              component={CreatePharmacyUsers}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-OfferPeriod/:id"
              component={CreateOfferPeriod}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-Order/:id"
              component={CreateOrder}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-ClientPosAccount/:id"
              component={CreateClientPosAccount}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-Segmentation/:id"
              component={CreateSegmentation}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-PredefinedOffer/:id"
              component={CreatePredefinedOffer}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-StandardSegmentOffer/:id"
              component={CreateStandardSegmentOffer}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-ValidOffer/:id"
              component={CreateValidOffer}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-CommercialCondition/:id"
              component={CreateCommercialCondition}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/edit-privilege/:id"
              component={Privilege}
            />
           
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/Feedback"
              component={FeedbackList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/ManualOffer"
              component={ManualOfferList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/EventConfiguration"
              component={EventConfigurationList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/EventHistory"
              component={EventHistoryList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/LocOrder"
              component={LocOrderList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/ProductLink"
              component={LinkTableList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/DraftTable"
              component={DraftTableList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/TermsEditor"
              component={TermsEditor}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/Languages"
              component={LanguagesTableList}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/labels"
              component={LocalizationList}
            />
            <Route
              exact
              path="/checkout"
              component={Checkout}
            />
            <Route
              exact
              path="/checkout/portugal"
              render={(props) => <LocCheckout {...props} loc={"Portugal"} type={"type1"} labels={portugalLabels} allowSkuIdentifierSearch={true}/>}
            />
            <Route
              exact
              path="/checkout/spain"
              render={(props) => <LocCheckout {...props} loc={"Spain"} type={"type2"} labels={spainLabels} allowSkuIdentifierSearch={false}/>}
            />
          </Switch>
        </MainWrapper>
      </ThemeProvider>
    </div>
  );
};

const mapStateToProps = ({ authReducer, language }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
    translation: authReducer.translation,
    selectedDefaultLanguage: language.selectedDefaultLanguage,
  };
};

export default withRouter(connect(mapStateToProps, { getTheme })(Routes));
