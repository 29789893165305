import React from "react";
import * as $ from "jquery";
import "./asideModal.scss";
import Button from "../../../../library/common/components/Button";
import { strings } from "../index";
import PropTypes from "prop-types";

const AsideModal = ({
  isCloseModal,
  id,
  form,
  handleFormData,
  modalTitle,
  classes,
  label,
  modalClass,
  isButton
}) => {
  const closeModal = () => {
    isCloseModal(false);
    $(`#${id}`).modal("hide");
  };

  const handleForm = () => {
    handleFormData();
  };
  return (
    <div
      data-backdrop="static"
      data-keyboard="false"
      className={`modal deleteModal timeLineTaskModal travelPlanModal ${
        modalClass ? modalClass : ""
      }`}
      id={`${id}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              <i
                className="fa fa-arrow-left mr-3 cursor-pointer"
                onClick={closeModal}
              />{" "}
              {modalTitle}
            </h5>
            <button
              type="button"
              className="close closeModalButtonWrapper"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span className="closeModalButton fs-25" aria-hidden="true">
                &times;
              </span>
            </button>
          </div>
          <div className="modal-body p-3 overflow">
            <div className="common-box-shadow p-3">
              {form()}
              {isButton && (
                <div className={`${classes} formData text-right`}>
                  <Button
                    styleClass="common-button"
                    onClick={handleForm}
                    value={label || strings.common_button_save}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AsideModal;

AsideModal.propTypes = {
  modalTitle: PropTypes.string,
  modalClass: PropTypes.string,
  label: PropTypes.string,
  classes: PropTypes.string,
  form: PropTypes.func.isRequired,
  handleFormData: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isButton: PropTypes.bool
};

AsideModal.defaultProps = {
  isButton: true,
  modalTitle: "",
  modalClass: "",
  label: "",
  classes: "",
  isCloseModal: () => {}
};
